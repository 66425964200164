<template>
  <main class="main" v-if="!loading">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <aside class="page__sidebar">
            <p class="page__title"><span :style="{backgroundColor: agent.color}" style="text-align: center; color: #FFFFFF;" class="dot">{{ Array.from(agent.item.name)[0].toUpperCase() }}</span> {{agent.item.name}}</p>

            <div class="separator"></div>

            <strong v-if="agent.license_file !== 'n\u00e3o'">Licença para coleta:</strong>
            <p class="sidebar__item" v-if="agent.license_file !== 'n\u00e3o'">
              <strong>Licença enviada:</strong> <a :href="agent.license_file" target="_blank">visualizar arquivo</a>
            </p>
            <p class="sidebar__item" v-if="agent.license_file !== 'n\u00e3o'">
              <strong>Data de expiração:</strong> {{agent.destination_certification_expiration | formatDate}}
            </p>
            <p class="sidebar__item">
              <strong :class="{'alert': agent.status_material === 'Aguardando',
                          'reprovado': agent.status_material === 'Reprovado',
                          'aprovado': agent.status_material === 'Aprovado'}">Status:</strong> {{agent.status_material}}
            </p>
            <div class="separator"></div>

            <button :class="{'active': typeAgentCollection === 'coletor-funcionario'}" @click="typeAgentCollection = 'coletor-funcionario'">
              <i class="fas fa-user-shield"></i>
              Agentes de coleta
            </button>
            <button :class="{'active': typeAgentCollection === 'coletor-point'}" @click="typeAgentCollection = 'coletor-point'">
              <i class="fas fa-map-marked-alt"></i>
              Pontos de coleta
            </button>
          </aside>
          <div class="page__content">
            <div class="sidebar__header">
              <h1 class="sidebar__title">{{ typeAgentCollection == 'coletor-funcionario' ?  'Agente de coleta' : 'Ponto de coleta' }}<br><h3 style="font-size: 16px;">{{agent.city.name}} - {{state.initials}}</h3></h1>
              <div class="buttons">
                <button type="button" class="button_outline"  @click.prevent.stop="agentEditing = formNew, modalAgent = true">Adicionar +</button>
              </div>
            </div>
            <div v-if="showStatus" class="error">
                  <p>
                    <i class="fas fa-exclamation-circle"></i>
                    Existem coletores com status desativado
                  </p>
                </div>
                <br>
            <div class="tab__body current" id="all">
              <table class="table">
                <thead>
                  <tr v-if="typeAgentCollection == 'coletor-funcionario'">
                    <th>Nome</th>
                    <th>Bairro</th>
                    <th>Mínimo</th>
                    <th>Valor</th>
                    <th>
                      <span>Opções</span>
                    </th>
                  </tr>
                  <tr v-else>
                    <th>Nome do ponto</th>
                    <th>Endereço</th>
                    <th>Coletor</th>
                    <th>Valor</th>
                    <th>
                      <span>Opções</span>
                    </th>
                  </tr>
                </thead>

                <tbody v-if="!loadingAgents && typeAgentCollection == 'coletor-funcionario'">
                  <tr :style="{backgroundColor: agent.collector.status !== 1 ? '#f17c7c33' : '#FFFFFF'}" v-for="(agent, index) in listAgents" :key="index">
                    <td>
                      <span>{{ agent.collector.name }}</span>
                    </td>
                    <td class="citiesCol">
                      <span>{{ agent.district ? agent.district.name : 'Todos' }}</span>
                    </td>
                    <td class="citiesCol">
                      <span>{{ agent.ammount }}</span>
                    </td>
                    <td class="citiesCol">
                      <span>R${{ agent.value }}</span>
                    </td>
                    <td class="citiesCol">
                        <span>
                          <button @click.prevent.stop="agentEditing = agent, modalAgent = true">
                            <i class="far fa-edit edit"></i>
                          </button>
                          <button  @click.prevent.stop="deleteMaterialUser(agent.id)">
                            <i class="fa fa-trash red" style="color: red; margin-left: 10px;"></i>
                          </button>
                        </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr :style="{backgroundColor: agent.collector.status !== 1 ? '#f17c7c33' : '#FFFFFF'}" v-for="(agent, index) in listAgents" :key="index">
                    <td>
                      <span>{{ agent.point.point_name }}</span>
                    </td>
                    <td class="citiesCol">
                      <span>{{ agent.point.street }} {{ agent.point.number !== null ? ', ' + agent.point.number : '' }}</span>
                    </td>
                    <td class="citiesCol">
                      <span>{{ agent.collector.name  }}</span>
                    </td>
                    <td class="citiesCol">
                      <span>R$ {{ agent.value }}</span>
                    </td>
                    <td class="citiesCol">
                        <span>
                          <button @click.prevent.stop="agentEditing = agent, modalAgent = true">
                            <i class="far fa-edit edit"></i>
                          </button>
                          <button @click.prevent.stop="deleteMaterialUser(agent.id)">
                            <i class="fa fa-trash" style="color: red; margin-left: 10px;"></i>
                          </button>
                        </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <modal-agent
      v-model="modalAgent"
      @change-modal="e => (modalAgent = e)"
      :cityCompanyItem="Number($route.params.id)"
      :cityCompanyItemUser="agent.id"
      :typeAgent.sync="typeAgentCollection"
      :cityId.sync="agent.city.id"
      :city.sync="agent.city.name + '-' + state.initials"
      :editing.sync="agentEditing">
    </modal-agent>
  </main>
</template>

<script>
import _ from 'lodash'
import swal from 'sweetalert'

export default {
  name: 'agente-coleta',
  components: {
    modalAgent: () => import('./components/modal-register-agent')
  },
  data: () => ({
    formNew: {
      id: null,
      role: null,
      city_company_item_id: null,
      district_id: null,
      district: null,
      ammount: null,
      value: null,
      collector_id: null,
      collector: {
        id: null,
        name: null,
        avatar: null,
        email: null,
        type: null,
        email_verified_at: null,
        status: null,
        device_id: null,
        activation_date: null,
        inactivity_date: null,
        deleted_at: null,
        created_at: null,
        updated_at: null,
        avatar_url: null
      },
      point: {
        id: null,
        point_name: null,
        city_company_item_id: null,
        cep: null,
        street: null,
        number: null,
        complement: null,
        district: null,
        city_id: null,
        id_neighborhood: null,
        province_id: null,
        country_id: null,
        latitude: null,
        longitude: null,
        created_at: null,
        updated_at: null,
        city: {
          id: null,
          name: null,
          ibge_code: null,
          province_id: null,
          created_at: null,
          updated_at: null,
          enabled: null
        },
        province: {
          id: null,
          name: null,
          initials: null,
          created_at: null,
          updated_at: null
        }
      }
    },
    formDefault: {
      id: null,
      role: null,
      city_company_item_id: null,
      district_id: null,
      district: null,
      ammount: null,
      value: null,
      collector_id: null,
      collector: {
        id: null,
        name: null,
        avatar: null,
        email: null,
        type: null,
        email_verified_at: null,
        status: null,
        device_id: null,
        activation_date: null,
        inactivity_date: null,
        deleted_at: null,
        created_at: null,
        updated_at: null,
        avatar_url: null
      },
      point: {
        id: null,
        point_name: null,
        city_company_item_id: null,
        cep: null,
        street: null,
        number: null,
        complement: null,
        district: null,
        city_id: null,
        id_neighborhood: null,
        province_id: null,
        country_id: null,
        latitude: null,
        longitude: null,
        created_at: null,
        updated_at: null,
        city: {
          id: null,
          name: null,
          ibge_code: null,
          province_id: null,
          created_at: null,
          updated_at: null,
          enabled: null
        },
        province: {
          id: null,
          name: null,
          initials: null,
          created_at: null,
          updated_at: null
        }
      }
    },
    modalAgent: false,
    agentEditing: null,
    agent: null,
    state: null,
    loading: true,
    typeAgentCollection: 'coletor-funcionario',
    listAgents: [],
    loadingAgents: true,
    showStatus: false
  }),
  async mounted () {
    await this.getAgent()
    await this.getCollectorsUsers()
  },
  methods: {
    async getAgent () {
      const response = await this.$http.get('/collectors/materials/' + this.$route.params.id)
      this.agent = response.data.data
    },
    async getState () {
      const response = await this.$http.get('/provinces')
      this.state = _.find(response.data, { id: this.agent.city.province_id })
    },
    async getCollectorsUsers () {
      this.loadingAgents = true
      const response = await this.$http.get('/collectors/materials/users?id=' + this.$route.params.id)
      this.listAgents = response.data.data.filter(value => value.role === this.typeAgentCollection)
      response.data.data.forEach((register) => {
        if (register.collector.status !== 1) {
          this.showStatus = true
        }
      })
    },
    async deleteMaterialUser (id) {
      const confirmDelete = await swal({
        title: 'Remover coletor',
        text: 'Deseja realmente remover esse coletor?',
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Cancelar',
            value: null,
            visible: true,
            className: 'swal-button--cancel',
            closeModal: true
          },
          confirm: {
            text: 'Confirmar',
            value: true,
            visible: true,
            className: 'swal-button--confirm',
            closeModal: true
          }
        },
        dangerMode: true
      })

      if (confirmDelete) {
        try {
          const url = '/collectors/materials/users'
          const response = await this.$http.delete(url, {
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
              id: id
            }
          })
          if (response.status === 200) {
            await swal({
              title: 'Coletor removido com sucesso!',
              icon: 'success'
            })
            await this.getCollectorsUsers()
          }
        } catch (err) {
          await swal('Oops!', 'Erro ao deletar material', 'error')
        }
      }
    }
  },
  watch: {
    agent () {
      this.getState()
    },
    state () {
      this.loading = false
    },
    listAgents () {
      this.loadingAgents = false
    },
    typeAgentCollection () {
      this.getCollectorsUsers()
    },
    modalAgent () {
      if (!this.modalAgent) {
        this.getCollectorsUsers()
      }
    }
  },
  filters: {
    formatDate (value) {
      if (value) {
        const date = new Date(value)
        return '' + (date.getDate() > 9 ? '' : '0') + date.getDate() + '/' + ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + '/' + date.getFullYear()
      }
    }
  }
}
</script>

<style scoped>
.separator {
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  width: 100%;
  margin: 1rem auto 1rem 0;
}
.page__sidebar .sidebar__item {
  padding-top: 15px;
}
.page__sidebar .sidebar__item a {
  color: #16b666;
}
.page__sidebar button {
  width: 100%;
  text-align: left;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: transparent;
  transition: all linear 0.2s;
}
.page__sidebar button:hover,
.page__sidebar button.active {
  background-color: #E0F8E6;
  color: #16b666;
}
.sidebar__header {
  display: flex;
  justify-content: space-between;
}

.table {
  table-layout: fixed;
}
.table tbody tr{
  background: transparent;
  transition: all linear 0.2s;
}
.table tbody tr:hover{
  background: #eeeeee33;
}

th:last-child {
  width: 104.2px;
}

th,
td {
  font-weight: 400 !important;
  width: 100%;
  text-align: left;
}

.table tbody tr td:nth-child(1) {
  width: 100%;
  font-size: 16px;
}

td:last-child{
  text-align: center !important;
}
td:last-child span {
  float: left;
  display: flex;
  justify-content: center;
}
td:last-child button{
  text-align: center;
  background: transparent;
  font-size: 15pt;
  transition: all linear 0.2s;
}
td:last-child button .edit{
  color: #009cceB3;
}
td:last-child button:hover .edit{
  color: #009cceFF;
}
.citiesCol span{
  width: 100%;
  display: inline-block;
}

.dot {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
  line-height: 42px;
}

.error{
  background-color: #f17c7c33;
  color: red;
  padding: 12px;
    border-radius: 7px;
}

</style>
